import { DocumentType, FeatureType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import compact from 'lodash/compact';
import { FeatureData, TaskData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Task';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import params from '~/params/task.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useTasks from '~/utils/useTasks';

const PageEspaceTasks: FC<
  PageProps & EspaceProps & UserProps & { pageContext: { tab?: number } }
> = ({ espace, location, pageContext: { tab }, user }) => {
  const { t } = useTranslation();
  const items = useTasks({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  const [features, setFeatures] = useState<DocumentType<FeatureType>[]>();

  useEffect(() => {
    const feature = new FeatureData({
      espace,
      espaceId: espace.id,
    });

    const unsub = feature.watch(
      docs => {
        if (setFeatures) {
          setFeatures(docs);
        }
      },
      {
        orderDirection: 'asc',
        orderField: 'label',
        wheres: {
          hidden: false,
        },
      },
    );

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [espace.id]);

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          filters={compact([
            features &&
              features.length > 0 && {
                label: 'Caractéristique',
                multiple: true,
                name: 'search.features',
                options: features.map(feature => ({
                  label: feature.label ?? feature.id,
                  value: feature.id,
                })),
                type: 'array',
              },
            {
              label: 'Priorité',
              name: 'priority',
              options: [
                {
                  label: 'Urgente',
                  value: 'urgent',
                },
                {
                  label: 'Haute',
                  value: 'high',
                },
                {
                  label: 'Normal',
                  value: 'normal',
                },
                {
                  label: 'Basse',
                  value: 'low',
                },
              ],
            },
          ])}
          itemList={Item}
          model={
            new TaskData({
              espace,
              params,
              espaceId: espace.id,
              wheres: {
                // parent: '',
              },
            })
          }
          search={location.search}
          subMenu={items}
          tabIndex={tab ?? 0}
          title={t('tasks.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceTasks);
